<script>
import AppInput from "@/components/Ui/AppInput.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "NotificationsSettings",
  components: { AppInput },
  emits: ["close"],

  data() {
    return {
      region: false,
      status: false,
      global: false,

      button_loader: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      window_width: (state) => state.system.window_width,
    }),
  },

  //   watch: {
  //     window_width(newValue) {
  //       if (newValue < 1024) {
  //         this.$router.push({ name: "ProfileNotificationsSettings" });
  //       }
  //     },
  //   },

  methods: {
    ...mapActions({
      setUserNotifications: "user/setUserNotifications",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    sendNotificationsSettings() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.setUserNotifications({
          region: this.region,
          status: this.status,
          global_events: this.global,
        })
          .then(() => {
            this.setNotification({
              type: "success",
              message: "Изменения сохранены",
            });

            this.$emit("close");
          })
          .catch(() => {
            this.setNotification({
              type: "error",
              message: "Не удалось сохранить изменения",
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },

  beforeMount() {
    this.region = this.user.notification_settings.region;
    this.status = this.user.notification_settings.status;
    this.global = this.user.notification_settings.global;
  },
};
</script>

<template>
  <div class="notifications">
    <h1 class="mobile-paddings">Настройка уведомлений</h1>

    <p class="mobile-paddings">
      Настройте уведомления, чтобы не пропустить важные события и новости в
      вашем регионе
    </p>

    <form class="mobile-paddings" @submit.prevent="sendNotificationsSettings">
      <AppInput
        id="email"
        v-model="user.email"
        type="email"
        label="Ваша электронная почта"
        disabled
      />

      <fieldset>
        <label class="app-checkbox" for="region">
          <input id="region" v-model="region" type="checkbox" />
          <span />
          Уведомлять о новых мероприятиях и новостях в вашем регионе
        </label>

        <label class="app-checkbox" for="status">
          <input id="status" v-model="status" type="checkbox" />
          <span />
          Уведомлять о мероприятиях вашего статуса
        </label>

        <label class="app-checkbox" for="global">
          <input id="global" v-model="global" type="checkbox" />
          <span />
          Уведомлять о мероприятиях федерального значения
        </label>
      </fieldset>

      <div class="notifications__actions">
        <button type="submit" class="button" :disabled="button_loader">
          Сохранить настройки
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.notifications {
  @media (max-width: 1023px) {
    margin: ($base-space * 10) 0 0;
    padding: 0 0 ($base-space * 20);
  }

  > h1 {
    @include h-2();
    color: $text-primary;

    @media (max-width: 1023px) {
      @include h-1();
    }
  }

  > p {
    margin: ($base-space * 2) 0 0;
    color: $text-third;
    @include s-text();
    max-width: 500px;

    @media (max-width: 1023px) {
      margin: ($base-space * 3) 0 0;
      @include m-text();
    }
  }

  > form {
    margin: ($base-space * 8) 0 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $base-space * 8;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
      gap: $base-space * 6;
    }

    > fieldset {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: $base-space * 3;
      padding: 0;
      border: none;
    }
  }

  &__actions {
    width: 100%;

    @media (max-width: 1023px) {
      position: fixed;
      bottom: 70px;
      left: 0;
      right: 0;
      background: $background-light;
      padding: ($base-space * 2) ($base-space * 8);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 2) ($base-space * 5);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 2) ($base-space * 5);
    }

    > button {
      width: 100%;
    }
  }
}
</style>
