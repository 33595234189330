<script>
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import AppInput from "@/components/Ui/AppInput.vue";
import AppTextarea from "@/components/Ui/AppTextarea.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import AppModal from "@/components/Ui/AppModal.vue";

export default {
  name: "SupportRequest",
  components: { AppModal, AppTextarea, AppInput },
  emits: ["close"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      email: "",
      message: "",
      button_loader: false,
      show_success_modal: false,
    };
  },

  validations() {
    return {
      email: {
        required: helpers.withMessage("Укажите электронную почту", required),
      },

      message: {
        required: helpers.withMessage("Укажите вопрос", required),
      },
    };
  },

  computed: {
    ...mapState({
      window_width: (state) => state.system.window_width,
      user: (state) => state.user.user,
    }),
  },

  watch: {
    window_width(newValue) {
      if (newValue < 1024) {
        this.$router.push({ name: "ProfileSupport" });
      }
    },
  },

  methods: {
    ...mapActions({
      sendSupportReport: "user/sendSupportReport",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    sendSupportRequest() {
      this.v$.$validate();

      if (!this.v$.$error && !this.button_loader) {
        this.button_loader = true;

        this.sendSupportReport({ message: this.message, email: this.email })
          .then(() => {
            this.show_success_modal = true;
            this.email = "";
            this.message = "";
          })
          .catch(() => {
            this.setNotification({
              type: "error",
              message: "Не удалось отправить сообщение",
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },

  mounted() {
    this.email = this.user.email;
  },
};
</script>

<template>
  <AppModal v-if="show_success_modal" @close="$emit('close')">
    <div class="notification-modal">
      <img src="../../assets/images/send.svg" alt="ticket" />

      <h3>Вопрос отправлен</h3>

      <p>
        Спасибо за ваш вопрос. Мы отправим ответ на указанную почту в течение
        рабочих суток
      </p>

      <div class="notification-modal__actions">
        <button type="button" class="button" @click="$emit('close')">
          Закрыть
        </button>
      </div>
    </div>
  </AppModal>

  <div class="support">
    <h1 class="mobile-paddings">Техподдержка</h1>

    <p class="mobile-paddings">
      Остались вопросы? Задайте их через форму обратной связи. Мы ответим на
      вашу почту в течение рабочих суток
    </p>

    <form class="mobile-paddings" @submit.prevent="sendSupportRequest">
      <AppInput
        id="email"
        v-model="email"
        :error="v$.email?.$errors[0]?.$message"
        label="Ваша электронная почта"
      />

      <AppTextarea
        id="message"
        v-model="message"
        label="Ваш вопрос"
        :error="v$.message?.$errors[0]?.$message"
      />

      <div class="support__actions">
        <button
          type="submit"
          class="button button_full-width"
          :disabled="button_loader"
        >
          Отправить вопрос
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.support {
  @media (max-width: 1023px) {
    margin: ($base-space * 10) 0 0;
    padding: 0 0 ($base-space * 20);
  }

  > h1 {
    @include h-2();
    color: $text-primary;

    @media (max-width: 1023px) {
      @include h-1();
    }
  }

  > p {
    margin: ($base-space * 2) 0 0;
    color: $text-third;
    @include s-text();
    max-width: 500px;

    @media (max-width: 1023px) {
      margin: ($base-space * 3) 0 0;
      @include m-text();
    }
  }

  > form {
    margin: ($base-space * 8) 0 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $base-space * 3;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }
  }

  &__actions {
    width: 100%;
    margin: ($base-space * 3) 0 0;

    @media (max-width: 1023px) {
      margin: 0;
      position: fixed;
      bottom: calc(69px + env(safe-area-inset-bottom));
      left: 0;
      right: 0;
      padding: ($base-space * 2) ($base-space * 8);
      background: $background-light;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 2) ($base-space * 5);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 2) ($base-space * 5);
    }

    > button {
      width: 100%;
    }
  }
}
</style>
