<script setup></script>

<template>
  <header class="layout-header">
    <div class="container">
      <RouterLink :to="{ name: 'LoginView' }">
        <img src="../../../assets/images/logo.svg" alt="logotype" />
      </RouterLink>

      <div class="layout-header__right right">
        <div class="profile-navigation">
          <RouterLink :to="{ name: 'LoginView' }">
            <button type="button" class="profile-navigation__avatar button">
              Войти
            </button>
          </RouterLink>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.layout-header {
  background: $background-light;
  display: none;
  border-bottom: 1px solid $stroke-fourth;
  position: fixed;
  width: 100%;
  z-index: 19;

  @include _md {
    display: flex;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $base-space * 8;
  }

  > .container {
    padding: ($base-space * 4) 0;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;

    > a {
      > img {
        width: 60px;
      }
    }
  }

  .profile-navigation {
    position: relative;

    &__avatar {
      height: 48px;
      padding: 10px 24px;
    }

    &__dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 8px);
      width: 400px;
      background: $background-light;
      @include main-shadow();
      border-radius: 16px;
      padding: ($base-space * 8);
      z-index: 10;
    }
  }

  .more-sections {
    position: relative;

    .more-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space;
      color: $text-primary;
      @include m-text();
      padding: ($base-space * 2) 0;

      .icon {
        width: 32px;
        height: 32px;
        background-color: $background-dark-gray;
        transition: transform 0.2s ease-in-out;

        &_rotated {
          transform: rotate(-90deg);
        }
      }
    }

    &:hover {
      & .more-sections__options {
        display: block;
      }
    }

    &__options {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;
      width: 432px;
      display: block;

      > nav {
        margin: ($base-space * 2) 0 0;
        padding: 24px;
        @include main-shadow();
        background: $background-light;
        border-radius: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        > button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          height: auto;
          width: 81px;

          > a {
            height: 56px;
            width: 56px;
            background: $background-ultralightlight-accent;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 4px;
            text-align: center;
            padding: ($base-space * 2);
            flex-flow: column;
            gap: calc($base-space / 2);
            color: $text-fourth;
            @include xs-text-bold();

            &.router-link-active {
              background: $background-accent;

              > i {
                background: $text-light;
                width: 32px;
                height: 32px;
              }
            }

            > i {
              background: $text-accent;
              width: 32px;
              height: 32px;
            }
          }

          > span {
            height: 32px;
            width: 81px;
            @include xs-text-bold;
            color: $text-primary;
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .layout-header {
    &__right {
      gap: $base-space * 6;
    }

    > .container {
      > nav {
        margin-left: 50px;

        > a {
          &:not(:first-of-type) {
            margin-left: $base-space * 6;
          }
        }

        .more-sections {
          margin-left: $base-space * 6;
        }
      }
    }
  }
}

.dropdown-fade-enter-active {
  animation: openTabs 0.1s linear forwards;

  @keyframes openTabs {
    from {
      opacity: 0;
    }

    to {
      opacity: 80%;
    }
  }
}

.dropdown-fade-leave-active {
  animation: closeTabs 0.1s linear forwards;

  @keyframes closeTabs {
    from {
      opacity: 80%;
    }

    to {
      opacity: 0;
    }
  }
}
</style>
