import API from "@/api";

const state = {
  foreignCompany: [],
  currentCompany: null,
  isPageLoaded: false,
  isLoading: true,
  total: 0,
};

const mutations = {
  SET_FOREIGN_COMPANY_LIST(store, payload) {
    store.foreignCompany = [...store.foreignCompany, ...payload.foreignCompany];
    store.total = payload.meta.total;
  },

  RESET_FOREIGN_COMPANY(store) {
    store.foreignCompany = [];
    store.total = 0;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },

  SET_CURRENT_FOREIGN_COMPANY(store, payload) {
    store.currentCompany = payload;
  },

  RESET_FOREIGN_CURRENT_COMPANY(store) {
    store.currentCompany = null;
  },

  TOGGLE_LOADING(store) {
    store.isLoading = false;
  },
};

const actions = {
  getForeignCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        page: data.page,
      };
      API.get(`api/companies/foreign`, {
        params,
      })
        .then((response) => {
          commit("SET_FOREIGN_COMPANY_LIST", {
            foreignCompany: response.data.data,
            meta: response.data?.meta,
          });
          commit("TOGGLE_PAGE_LOADING");
          commit("TOGGLE_LOADING");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadForeignCompanyById({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`api/companies/foreign/${id}`)
        .then((response) => {
          commit("SET_CURRENT_FOREIGN_COMPANY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
