<script setup>
const steps = [
  {
    title: "Тариф",
    route: "ProfileCustomTariffSelect",
  },
  {
    title: "Аккаунты",
    route: "ProfileCustomTariffAccounts",
  },
  {
    title: "Оплата",
    route: "ProfileCustomTariffPayment",
  },
];
</script>

<template>
  <div class="steps">
    <RouterLink
      v-for="(step, idx) in steps"
      :key="step.route"
      class="step"
      :to="{ name: step.route }"
    >
      <div class="step-position">{{ idx + 1 }}</div>
      <div class="step-title">{{ step.title }}</div>
    </RouterLink>
  </div>
</template>

<style scoped lang="scss">
.steps {
  display: flex;
  height: 52px;
  justify-content: space-between;
  border: 1px solid #d6d8e1;
  border-left: none;
  border-right: none;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;
    gap: $base-space * 2;
    color: $text-fourth;
    background: #f7f8fa;

    .step-title {
      @include xl-text;
    }

    .step-position {
      width: 20px;
      height: 20px;
      border-radius: 20em;
      border: 1px solid $text-fifth;
      @include s-text;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.router-link-active {
      color: $text-accent;

      .step-position {
        border-color: $text-accent;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $text-accent;

        @include _md {
          bottom: 0;
        }
      }
    }
  }

  @include _md {
    margin: $base-space * 4 0;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    .step {
      background: #fff;
    }
  }
}
</style>
