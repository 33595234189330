import API from "@/api";

const state = {
  counterEvents: [],
  eventCalendar: [],
  myEventCalendar: null,
  totalMyEvents: 0,
  publicEventCalendar: null,
  totalPublicEvents: 0,
  courseEventCalendar: null,
  totalCourseEvents: 0,
  communityEventCalendar: null,
  totalCommunityEvents: 0,
  currentPersonalEvent: null,
  isLoading: true,
  total: 0,
};

const mutations = {
  SET_COUNTER_EVENT_CALENDAR_LIST(store) {
    store.counterEvents = [store.counterEvents];
  },

  SET_EVENT_CALENDAR_LIST(store) {
    store.eventCalendar = [store.eventCalendar];
  },

  SET_PERSONAL_EVENT_CALENDAR_LIST(store, payload) {
    if (!store.myEventCalendar) {
      store.myEventCalendar = [];
    }
    store.myEventCalendar = [
      ...store.myEventCalendar,
      ...payload.myEventCalendar,
    ];
    store.totalMyEvents = payload.totalMyEvents;
  },

  SET_PERSONAL_EVENT(store, payload) {
    store.currentPersonalEvent = payload;
  },

  SET_PUBLIC_EVENT_CALENDAR_LIST(store, payload) {
    if (!store.publicEventCalendar) {
      store.publicEventCalendar = [];
    }
    store.publicEventCalendar = [
      ...store.publicEventCalendar,
      ...payload.publicEventCalendar,
    ];
    store.totalPublicEvents = payload.totalPublicEvents;
  },

  SET_COURSE_EVENT_CALENDAR_LIST(store, payload) {
    if (!store.courseEventCalendar) {
      store.courseEventCalendar = [];
    }
    store.courseEventCalendar = [
      ...store.courseEventCalendar,
      ...payload.courseEventCalendar,
    ];
    store.totalCourseEvents = payload.totalCourseEvents;
  },

  SET_COMMUNITY_EVENT_CALENDAR_LIST(store, payload) {
    if (!store.communityEventCalendar) {
      store.communityEventCalendar = [];
    }
    store.communityEventCalendar = [
      ...store.communityEventCalendar,
      ...payload.communityEventCalendar,
    ];
    store.totalCommunityEvents = payload.totalCommunityEvents;
  },

  RESET_EVENT_CALENDAR(store) {
    store.eventCalendar = null;
    store.myEventCalendar = null;
    store.publicEventCalendar = null;
    store.courseEventCalendar = null;
    store.communityEventCalendar = null;
    store.total = 0;
  },

  RESET_MY_EVENT_CALENDAR(store) {
    store.myEventCalendar = [];
  },

  TOGGLE_LOADING(store) {
    store.isLoading = false;
  },
};

const actions = {
  getCounterEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        types: data.types,
        from: data.from,
        to: data.to,
        region_id: data.region_id,
      };

      API.get(`api/events/dates`, { params })
        .then((response) => {
          commit("SET_COUNTER_EVENT_CALENDAR_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storeNewEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.postForm(`api/events`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  subscribeEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.postForm(`api/events/${data.event_id}/subscribe`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unsubscribeEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.postForm(`api/events/${data.event_id}/unsubscribe`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.postForm(`api/events/${data.event_id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteEvent(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/events/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSpecificEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/events/${data.event_id}`, data)
        .then((response) => {
          commit("SET_PERSONAL_EVENT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getEventCalendar({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        from: data.from,
        to: data.to,
        types: data.type ?? [],
        page: data.page,
        tariff_ids: data.tariff_ids,
      };

      API.get(`api/events/calendar`, { params })
        .then((response) => {
          if (typeof response.data.personal !== "undefined") {
            commit("SET_PERSONAL_EVENT_CALENDAR_LIST", {
              myEventCalendar: response.data?.personal.data,
              totalMyEvents: response.data?.personal?.meta?.total,
            });
          }
          if (typeof response.data.public !== "undefined") {
            commit("SET_PUBLIC_EVENT_CALENDAR_LIST", {
              publicEventCalendar: response.data?.public.data,
              totalPublicEvents: response.data?.public?.meta?.total,
            });
          }
          if (typeof response.data.course !== "undefined") {
            commit("SET_COURSE_EVENT_CALENDAR_LIST", {
              courseEventCalendar: response.data?.course.data,
              totalCourseEvents: response.data?.course?.meta?.total,
            });
          }
          if (typeof response.data.community !== "undefined") {
            commit("SET_COMMUNITY_EVENT_CALENDAR_LIST", {
              communityEventCalendar: response.data?.community.data,
              totalCommunityEvents: response.data?.community?.meta?.total,
            });
          }
          commit("TOGGLE_LOADING");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
