<script setup>
import { onMounted, onUnmounted, ref } from "vue";

defineOptions({
  name: "AppObserver",
});

const props = defineProps({
  options: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["intersect"]);

const observer = ref(null);

const observerRef = ref(null);

onMounted(() => {
  const options = props.options ?? {};

  observer.value = new IntersectionObserver(([entry]) => {
    if (entry && entry.isIntersecting) {
      emit("intersect");
    }
  }, options);

  observer.value.observe(observerRef.value);
});

onUnmounted(() => {
  observer.value.disconnect();
});
</script>

<template>
  <div ref="observerRef" class="observer" />
</template>

<style lang="scss" scoped>
.app-observer {
}
</style>
