<script>
import { activeScroll, disableScroll } from "@/package/helpers/scrollActions";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import { toDataURL } from "@/utils/files";

export default {
  name: "QrModal",
  components: { VueQr: vueQr },
  emits: ["closeModal"],

  props: {
    urlSrc: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      toDataURL,
      qrImageURL: "",
    };
  },

  methods: {
    async download() {
      const a = document.createElement("a");
      a.href = await this.toDataURL(this.qrImageURL);
      a.download = "qr-code";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    handOverImg(dataUrl) {
      this.qrImageURL = dataUrl;
    },
  },

  beforeMount() {
    disableScroll();
  },

  unmounted() {
    activeScroll();
  },
};
</script>

<template>
  <div class="qr-modal">
    <div class="qr-modal__wrapper wrapper">
      <div class="wrapper__body body">
        <span class="body__qr-download">
          <VueQr
            :text="urlSrc"
            :callback="handOverImg"
            :margin="172"
            :size="680"
          />
        </span>
        <span class="body__qr-visibility">
          <VueQr :text="urlSrc" :margin="0" :size="220" />
        </span>
      </div>

      <div class="wrapper__footer">
        <button class="button" type="submit" @click="download">
          Скачать QR-код
        </button>

        <button
          class="button button_secondary"
          type="button"
          @click="$emit('closeModal')"
        >
          Отмена
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.qr-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  background: $background-overlay;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background: $background-light;
    padding: ($base-space * 12) ($base-space * 6);
    border-radius: 24px;
    max-height: 95vh;
    overflow: auto;
    max-width: 580px;
    width: calc(100vw - 64px);

    .body {
      display: flex;
      justify-content: center;

      &__qr-download {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      &__qr-visibility {
        display: flex;
      }
    }

    &__footer {
      margin-top: 40px;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 8px;
    }

    @media (max-width: 1023px) {
      padding: ($base-space * 8) ($base-space * 8);
      width: calc(100vw - 64px);
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6) ($base-space * 6);
      width: calc(100vw - 48px);
    }

    @media (max-width: 424px) {
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }
  }
}
</style>
