import API from "@/api";

const state = {
  allInvestmentItems: [],
  investmentOffersItems: [],
  investmentRequestItems: [],
  myInvestmentItems: [],
  isAddingItem: false,
  isLoading: false,
  isPageLoaded: false,
  currentItem: null,
};

const mutations = {
  SET_CURRENT_ITEM(store, payload) {
    store.currentItem = payload;
  },

  SET_ALL_INVESTMENT_ITEMS(store, payload) {
    store.allInvestmentItems = [...store.allInvestmentItems, ...payload];
  },

  SET_INVESTMENT_OFFERS_ITEMS(store, payload) {
    store.investmentOffersItems = [...store.investmentOffersItems, ...payload];
  },

  SET_MY_INVESTMENT_ITEMS(store, payload) {
    store.myInvestmentItems = [...store.myInvestmentItems, ...payload];
  },

  SET_INVESTMENT_REQUEST_ITEMS(store, payload) {
    store.investmentRequestItems = [
      ...store.investmentRequestItems,
      ...payload,
    ];
  },

  RESET_INVESTMENT(store) {
    store.allInvestmentItems = [];
    store.investmentOffersItems = [];
    store.investmentRequestItems = [];
    store.myInvestmentItems = [];
    store.isLoading = false;
    store.isPageLoaded = false;
  },

  TOGGLE_LOADING(store, payload) {
    store.isLoading = payload ?? !store.isLoading;
  },

  TOGGLE_PAGE_LOADING(store) {
    store.isPageLoaded = true;
  },

  SET_ADDING_INVESTMENT_ITEM(store, payload) {
    store.isAddingItem = payload;
  },
};

const actions = {
  loadInvestmentProgram({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        page: data.page,
      };
      API.get(`api/investment/items`, {
        params,
      })
        .then((response) => {
          commit("SET_ALL_INVESTMENT_ITEMS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("TOGGLE_PAGE_LOADING");
        });
    });
  },

  loadInvestmentProgramOffers({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        page: data.page,
      };
      API.get(`api/investment/items/offers`, {
        params,
      })
        .then((response) => {
          commit("SET_INVESTMENT_OFFERS_ITEMS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("TOGGLE_PAGE_LOADING");
        });
    });
  },

  loadInvestmentProgramRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        page: data.page,
      };
      API.get(`api/investment/items/requests`, {
        params,
      })
        .then((response) => {
          commit("SET_INVESTMENT_REQUEST_ITEMS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("TOGGLE_PAGE_LOADING");
        });
    });
  },

  loadMyInvestmentProgram({ commit }, data) {
    return new Promise((resolve, reject) => {
      const params = {
        page: data.page,
      };
      API.get(`api/me/investment_items`, {
        params,
      })
        .then((response) => {
          commit("SET_MY_INVESTMENT_ITEMS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("TOGGLE_PAGE_LOADING");
        });
    });
  },

  async addInvestmentItem({ commit }, payload) {
    try {
      commit("SET_ADDING_INVESTMENT_ITEM", true);
      const {
        type,
        first_name,
        last_name,
        company,
        position,
        direction,
        description,
        price,
        doc,
        email,
        phone,
      } = payload;
      const response = await API.postForm(`api/investment/items`, {
        type,
        first_name,
        last_name,
        company,
        position,
        directions: direction,
        description,
        amount: price,
        files: doc,
        email,
        phone,
      });
      return Promise.resolve(response.data?.data?.id);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      commit("SET_ADDING_INVESTMENT_ITEM", false);
    }
  },

  setFilePost(_, payload) {
    const params = {
      file: payload.file,
    };
    return new Promise((resolve, reject) => {
      API.postForm(
        `api/investment/items/${payload.investItem}/media/add`,
        params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editInvestmentItem(store, payload) {
    const currentMedia = payload.currentMedia;
    const addMediaNormalize = [];

    payload.addMedia?.forEach((i) => {
      addMediaNormalize.push(
        store.dispatch("setFilePost", {
          file: i,
          investItem: payload.id,
        })
      );
    });

    let addedMediaId = null;

    return new Promise((resolve, reject) => {
      Promise.all(addMediaNormalize).then((response) => {
        addedMediaId = response.map((resp) => {
          return resp.data.data.id;
        });

        const params = {
          type: payload.type ?? "",
          first_name: payload.first_name ?? "",
          last_name: payload.last_name ?? "",
          company: payload.company ?? "",
          position: payload.position ?? "",
          directions: payload.directions ?? "",
          description: payload.description ?? "",
          amount: payload.amount ?? "",
          email: payload.email ?? "",
          phone: payload.phone ?? "",
          media: [...currentMedia, ...addedMediaId],
        };

        API.patch(`api/investment/items/${payload.id}`, params)
          .then((responses) => {
            resolve(responses);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },

  getCurrentItem({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`api/investment/items/${payload.id}`)
        .then((response) => {
          commit("SET_CURRENT_ITEM", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteUserCard(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(`api/investment/items/${payload.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeModeratorComment(_, data) {
    const params = {
      version_id: data.version_id,
    };
    return new Promise((resolve, reject) => {
      API.delete(`api/me/investment_items/${data.item_id}/moderator_comment`, {
        params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
