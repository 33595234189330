<script setup></script>

<template>
  <div class="grid">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.grid {
  display: flex;
  flex-direction: column;
  gap: $base-space * 2;
  background: #f7f8fa;
  flex: 1;
}
</style>
