<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  title: {
    type: String,
    required: true,
  },

  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const handleSelect = () => {
  emit("update:modelValue", true);
};
</script>

<template>
  <div
    class="radio-box"
    :class="{ selected: modelValue }"
    @click="handleSelect"
  >
    <div class="circle" />
    <div class="title">{{ title }}</div>
  </div>
</template>

<style scoped lang="scss">
.radio-box {
  border: 1px solid #c9cbd7;
  border-radius: 16px;
  padding: $base-space * 3 $base-space * 5;
  display: flex;
  align-items: center;
  gap: $base-space * 3;

  &:hover {
    cursor: pointer;
  }

  .circle {
    border: 1px solid $text-fourth;
    border-radius: 20em;
    width: 16px;
    height: 16px;
  }

  .title {
    @include m-text;
    color: $text-fourth;
  }

  &.selected {
    border-color: $text-accent;
    .title {
      color: $text-accent;
    }
    .circle {
      border-color: $text-accent;
      border-width: 4px;
    }
  }
}
</style>
