import { createStore } from "vuex";
import system from "./system.store";
import user from "./user.store";
import events from "./events.store";
import news from "./news.store";
import tariffs from "./tariffs.store";
import blog from "./blog.store";

import communities from "./communities.store";
import investmentProgram from "./investment-program.store";
import community from "./community.store";
import companies from "./companies.store";
import company from "./company.store";
import services from "./services.store";
import service from "./service.store";
import courses from "./courses.store";
import course from "./course.store";
import customTariff from "./customTariff.store";
import authorCourses from "./author-courses.store";
import foreignCompany from "./foreign-company.store";
import eventCalendar from "./event-calendar.store";
import regionalRepresentative from "./regional-representative.store";
import chat from "./chat.store";
import instructions from "./instructions.store";
import communityComment from "./community-comments.store";
import blogComments from "./blog-comments.store";
import blogReactions from "./blog-reactions.store";
import notifications from "./notifications.store";

export default createStore({
  modules: {
    system,
    user,
    events,
    news,
    blog,
    instructions,
    notifications,
    regionalRepresentative,
    tariffs,
    communities,
    investmentProgram,
    community,
    communityComment,
    blogComments,
    blogReactions,
    companies,
    company,
    services,
    service,
    courses,
    course,
    authorCourses,
    foreignCompany,
    eventCalendar,
    customTariff,
    chat,
  },
});
