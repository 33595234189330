<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import RadioBox from "@/components/Ui/RadioBox.vue";
import { useStore } from "vuex";

const store = useStore();

const handleUpdatePaymentMethod = (method) => {
  store.commit("customTariff/UPDATE_PAYMENT_METHOD", method);
};
</script>

<template>
  <ContentBox title="Способ оплаты">
    <div class="grid">
      <RadioBox
        title="Корпоративной картой"
        :model-value="store.state.customTariff.paymentMethod === 'card'"
        @update:modelValue="handleUpdatePaymentMethod('card')"
      />
      <RadioBox
        title="Оплата по счету"
        :model-value="store.state.customTariff.paymentMethod === 'check'"
        @update:modelValue="handleUpdatePaymentMethod('check')"
      />
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.grid {
  display: flex;
  flex-direction: column;
  gap: $base-space * 3;
}
</style>
