<script>
// import { activeScroll, disableScroll } from "@/package/helpers/scrollActions";

export default {
  name: "AppDrawer",
  emits: ["close"],

  props: {
    title: {
      type: String,
      default: undefined,
    },
  },

  beforeMount() {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  },

  unmounted() {
    const body = document.querySelector("body");
    body.style.removeProperty("overflow");
  },
};
</script>

<template>
  <div class="app-drawer" @click="$emit('close')">
    <div class="app-drawer__wrapper" @click.stop>
      <button type="button" @click.stop="$emit('close')">
        <i class="icon-close" />
      </button>

      <h4>{{ title }}</h4>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-drawer {
  background: $background-overlay;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &__wrapper {
    background: $background-light;
    padding: ($base-space * 8) ($base-space * 6) ($base-space * 6);
    border-radius: 24px 24px 0 0;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    overscroll-behavior: contain;
    position: relative;

    @media (display-mode: standalone) {
      padding-bottom: calc(66px + env(safe-area-inset-bottom));
    }

    > button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: none;
      border: none;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-fifth;
      }
    }

    > h4 {
      @include h-2();
      color: $text-primary;
      width: calc(100% - 48px);
    }
  }
}
</style>
