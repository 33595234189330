import HttpClient from "@/api";
import chatService from "@/services/ChatService";

const state = {
  isLoadingNotification: false,
  notificationsList: null,
  perPage: 15,
  unreadNotification: 0,
  unreadMessages: 0,
  currentNotification: null,
};

const mutations = {
  SET_NOTIFICATIONS(store, payload) {
    store.notificationsList = [...(store.notificationsList ?? []), ...payload];
  },

  SET_UNREAD_NOTIFICATIONS_COUNT(store, payload) {
    store.unreadNotification = payload.countUnreadNotification;
  },

  RESET_UNREAD_NOTIFICATIONS_COUNT(store) {
    store.unreadNotification = 0;
  },

  READ_NOTIFICATION(store) {
    store.unreadNotification = store.unreadNotification - 1;
  },

  SET_UNREAD_MESSAGES_COUNT(store, payload) {
    store.unreadMessages = payload.countUnreadMessage;
  },

  SET_CURRENT_NOTIFICATION(store, payload) {
    store.currentNotification = payload;
  },

  CLEAR_NOTIFICATION(store) {
    store.currentNotification = null;
  },

  RESET_NOTIFICATIONS(store) {
    store.notificationsList = null;
  },

  TOGGLE_LOADING_NOTIFICATIONS(store, payload) {
    store.isLoadingInfoUser = payload ?? !store.isLoadingInfoUser;
  },
};

const actions = {
  async getUnreadCount(store) {
    try {
      const response = await HttpClient.get(`api/me/notifications/count`);
      store.commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
        countUnreadNotification: response.data.unread_notifications_count,
      });
      store.commit("SET_UNREAD_MESSAGES_COUNT", {
        countUnreadMessage: response.data.unread_messages_count,
      });
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить количество непрочитанных уведомлений")
      );
    }
  },

  async readNotifications() {
    try {
      await HttpClient.post(`api/me/notification/read`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось прочитать уведомления"));
    }
  },

  async deleteNotifications() {
    try {
      await HttpClient.delete(`api/me/notifications`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось удалить уведомления"));
    }
  },

  async loadNotificationById(store, data) {
    try {
      const response = await HttpClient.get(`api/me/notifications/${data.id}`);
      store.commit("SET_CURRENT_NOTIFICATION", response.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить информацию об уведомлении")
      );
    }
  },

  async deleteNotification(store, data) {
    try {
      await HttpClient.delete(`api/me/notifications/${data.notification_id}`);
    } catch (e) {
      return Promise.reject(new Error("Не удалось удалить уведомление"));
    }
  },

  async getAllNotifications(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", true);
      const params = {
        per_page: store.perPage,
        page: payload.page,
      };
      const response = await HttpClient.get(`api/me/notifications/all`, {
        params,
      });

      store.commit("SET_NOTIFICATIONS", response.data.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить список непрочитанных уведомлений")
      );
    } finally {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", false);
    }
  },

  async getUnreadNotifications(store, payload) {
    try {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", true);
      const params = {
        per_page: store.perPage,
        page: payload.page,
      };
      const response = await HttpClient.get(`api/me/notifications/unread`, {
        params,
      });

      store.commit("SET_NOTIFICATIONS", response.data.data);
    } catch (e) {
      return Promise.reject(
        new Error("Не удалось получить список непрочитанных уведомлений")
      );
    } finally {
      store.commit("TOGGLE_LOADING_NOTIFICATIONS", false);
    }
  },

  async shutdown() {
    chatService.disconnect();
  },

  async init() {
    await chatService.connectOnChat();
    await this.dispatch("notifications/listner");
  },

  actionsChat(store, data) {
    switch (data.data.event) {
      case "App\\Events\\NotificationCounters":
        store.commit("SET_UNREAD_MESSAGES_COUNT", {
          countUnreadMessage: data.data.unread_messages_count,
        });
        break;

      case "App\\Events\\ChatMessageSent":
        store.commit("SET_UNREAD_MESSAGES_COUNT", {
          countUnreadMessage: data.data.unread_chat_count,
        });

        break;

      case "App\\Events\\SystemNotificationByTariffSent":
        store.commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
          countUnreadNotification: data.data.unread_notifications_count,
        });

        break;

      case "App\\Events\\NotificationSent":
        store.commit("SET_UNREAD_NOTIFICATIONS_COUNT", {
          countUnreadNotification: data.data.unread_notifications_count,
        });

        break;
    }
  },

  async listner(store) {
    const userId = store.rootState.user.user.id;
    const centrifuge = await chatService.connectionStart();

    const notifications = `notifications:user#user::${userId}`;
    const direct = `chats:user:direct#user::${userId}`;
    const channel = `chats:user:group#user::${userId}`;

    centrifuge.subscribe(notifications, function (message) {
      store.dispatch("actionsChat", message);
    });

    centrifuge.subscribe(direct, function (message) {
      store.dispatch("actionsChat", message);
      store.dispatch("chat/actionsChat", message, { root: true });
    });

    centrifuge.subscribe(channel, function (message) {
      store.dispatch("actionsChat", message);
      store.dispatch("chat/actionsChat", message, { root: true });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
