<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import { useStore } from "vuex";
import CustomTariffCounter from "@/components/CustomTariff/CustomTariffCounter.vue";
import { computed, onBeforeMount } from "vue";
import { useDebounceFn } from "@vueuse/core";

const store = useStore();

const tariffs = computed(() =>
  store.state.tariffs.tariffs.filter(
    (tariff) => tariff.price_original > 0 && tariff.code !== "master"
  )
);

const recalculate = useDebounceFn(() => {
  store.dispatch("customTariff/calculate");
}, 300);

const handleUpdate = (payload) => {
  store.commit("customTariff/SET_TARIFF", {
    id: payload.tariff.id,
    count: payload.count,
  });
  recalculate();
};

onBeforeMount(() => {
  store.commit(
    "customTariff/SET_TARIFFS",
    tariffs.value.map((t) => t.id)
  );
});
</script>

<template>
  <ContentBox
    title="Количество тарифов"
    description="Выберите не менее трех тарифов"
  >
    <div class="tariffs">
      <CustomTariffCounter
        v-for="tariff in tariffs"
        :key="tariff.id"
        :tariff="tariff"
        :count="store.state.customTariff.selectedTariffs[tariff.id]"
        @select="handleUpdate"
      />
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.tariffs {
  display: flex;
  flex-direction: column;
  gap: $base-space * 3;
}
</style>
