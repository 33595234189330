import { useStore } from "vuex";
import { computed, onBeforeMount, onBeforeUnmount } from "vue";

const usePage = (options) => {
  const store = useStore();

  onBeforeMount(() => {
    store.commit("system/SET_PAGE", {
      isPageLoading: options.loading ?? false,
      grayBackground: options.grayBackground ?? false,
      isOnlyMobilePage: options.isOnlyMobilePage ?? false,
      noNavigationBar: options.noNavigationBar ?? false,
      isOnlyTopGap: options.isOnlyTopGap ?? false,
    });
  });

  onBeforeUnmount(() => {
    store.commit("system/RESET_PAGE");
  });

  const loaded = () => {
    store.commit("system/SET_PAGE_LOADED");
  };

  const isLoading = computed(() => store.state.system.isPageLoading);

  return { loaded, isLoading: isLoading.value };
};

export { usePage };
export default usePage;
