<script setup>
import AppTabs from "@/components/Ui/AppTabs.vue";
import { NOTIFICATIONS_TAB } from "@/package/constants/notfications-constant";
import { computed, defineEmits, onMounted, onUnmounted, ref, watch } from "vue";
import NotificationsList from "@/components/Notifications/NotificationsList.vue";
import DropdownNotification from "@/components/Notifications/DropdownNotification.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

defineOptions({
  name: "NotificationsDropdown",
});

const store = useStore();
const route = useRoute();

const currentTab = ref("AllNotifications");

const emit = defineEmits(["closeDropdownHandler"]);

const isSelectorOpened = ref(false);

const isShowDeleteNotifications = ref(false);

const allNotificationsPage = ref(1);
const unreadNotificationsPage = ref(1);

const closeDropdownHandler = () => {
  emit("closeDropdownHandler");
};

const itsNotification = computed(() => route.name === "ProfileNotifications");

watch(
  () => currentTab.value,
  () => {
    store.commit("notifications/RESET_NOTIFICATIONS");
    getNotifications();
  }
);

const getNotifications = () => {
  if (currentTab.value === "AllNotifications") {
    const params = {
      page: 1,
    };
    store.dispatch("notifications/getAllNotifications", params);
  }
  if (currentTab.value === "UnreadNotifications") {
    const params = {
      page: 1,
    };
    store.dispatch("notifications/getUnreadNotifications", params);
  }
};

const intersectedHandler = () => {
  if (currentTab.value === "AllNotifications") {
    allNotificationsPage.value += 1;
    const params = {
      page: allNotificationsPage.value,
    };
    store.dispatch("notifications/getAllNotifications", params);
  } else {
    unreadNotificationsPage.value += 1;
    const params = {
      page: unreadNotificationsPage.value,
    };
    store.dispatch("notifications/getUnreadNotifications", params);
  }
};

const readAllNotification = () => {
  store.dispatch("notifications/readNotifications").then(() => {
    store.commit("notifications/RESET_NOTIFICATIONS");
    getNotifications();
    store.commit("notifications/RESET_UNREAD_NOTIFICATIONS_COUNT");
  });
  isSelectorOpened.value = false;
};

const removeAllNotification = () => {
  store.dispatch("notifications/deleteNotifications").then(() => {
    store.commit("notifications/RESET_NOTIFICATIONS");
    getNotifications();
    store.commit("notifications/RESET_UNREAD_NOTIFICATIONS_COUNT");
    isShowDeleteNotifications.value = false;
  });
  isSelectorOpened.value = false;
};

const hideRemoveNotice = () => {
  isShowDeleteNotifications.value = false;
};
const openRemoveNotice = () => {
  isShowDeleteNotifications.value = true;
};

onMounted(async () => {
  if (!itsNotification.value) {
    const params = {
      page: allNotificationsPage.value,
    };
    await store.dispatch("notifications/getAllNotifications", params);
  }
});

onUnmounted(() => {
  if (!itsNotification.value) {
    store.commit("notifications/RESET_NOTIFICATIONS");
  }
});
</script>

<template>
  <div class="notifications-dropdown">
    <div class="notifications-dropdown__title title">
      <span class="title__label">Уведомления</span>
      <div class="title__button-container button-container">
        <button
          class="button-container__button-more button-more"
          @click="isSelectorOpened = !isSelectorOpened"
        >
          <i class="icon-more" />
        </button>
        <DropdownNotification
          :is-active="isSelectorOpened"
          :is-show-delete-notifications="isShowDeleteNotifications"
          @readAllNotification="readAllNotification"
          @removeAllNotification="removeAllNotification"
          @hideRemoveNotice="hideRemoveNotice"
          @openRemoveNotice="openRemoveNotice"
          @hideOptions="isSelectorOpened = false"
        />
      </div>
    </div>
    <div class="notifications-dropdown__options-tabs">
      <AppTabs v-model="currentTab" :tabs="NOTIFICATIONS_TAB" />
    </div>
    <NotificationsList
      @closeDropdownHandler="closeDropdownHandler"
      @intersectedNotificationHandler="intersectedHandler"
    />
  </div>
</template>

<style lang="scss" scoped>
.notifications-dropdown {
  position: absolute;
  display: flex;
  right: 0;
  top: 46px;
  width: 450px;
  height: 600px;
  padding: 32px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: $background-light;
  z-index: 30;
  box-shadow: 0 4px 16px 0 rgba(5, 10, 43, 0.1);

  .title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 24px;
    justify-content: space-between;

    &__label {
      @include h-2;
      color: $text-primary;
      display: flex;
      width: 100%;
    }

    .button-container {
      width: 36px;
      height: 36px;
      position: relative;

      .button-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        > i {
          rotate: 90deg;
          width: 24px;
          height: 24px;
          background: $text-primary;
        }
      }
    }
  }

  &__options-tabs {
    display: flex;
    padding: 0 24px;
    align-items: center;
    gap: 8px;

    ::v-deep(.app-tabs) {
      display: grid;
      text-align: center;
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      .tab {
        cursor: pointer;
        background: none;
        display: flex;
        height: 40px;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: $text-accent;
        white-space: nowrap;
        border: 1px solid $stroke-accent;
        transition: color 0.15s ease-out;
        background: $text-light;
        border-radius: 12px;

        &_selected {
          background: $background-accent;
          color: $text-light !important;
          transition: background 50ms ease-out;
        }
      }
    }
  }
}
</style>
