import API from "@/api";

const state = {
  scroll_height: 0,
  window_width: 0,
  show_regions_modal: false,
  regions: "",
  notification: "",
  notification_timer: "",
  grayBackground: false,
  isAppLoading: false,
  isPageLoading: false,
  isMobileSearchOpen: false,
  isShareModalOpen: false,
  isOnlyMobilePage: false,
  isOnlyTopGap: false,
  shareModalUrl: null,
  searchText: "",
  isMobileFloatingActionVisible: false,
  noNavigationBar: false,
  isTrialPeriodTariffOpen: false,
  isOpenUnauthModal: false,
};

const getters = {
  isMobile(state) {
    return state.window_width < 1024;
  },

  isDesktop(state) {
    return state.window_width > 1023;
  },
};

const mutations = {
  SET_REGIONS_LIST(store, payload) {
    store.regions = payload;
  },

  SET_SCROLL_HEIGHT(store) {
    store.scroll_height = window.scrollY;
  },

  SET_WINDOW_WIDTH(store) {
    store.window_width = window.innerWidth;
  },

  CHANGE_REGIONS_MODAL_VIEW(store, payload) {
    store.show_regions_modal = payload ?? !store.show_regions_modal;
  },

  INPUT_SEARCH_QUERY(store, payload) {
    store.searchText = payload;
  },

  RESET_SEARCH_QUERY(store) {
    store.searchText = "";
  },

  TOGGLE_MOBILE_FLOATING_ACTION(store, payload) {
    store.isMobileFloatingActionVisible =
      payload ?? !store.isMobileFloatingActionVisible;
  },

  SET_NOTIFICATION(store, payload) {
    if (store.notification) {
      clearTimeout(store.notification_timer);
      store.notification_timer = "";
    }

    store.notification = payload;
    store.notification_timer = setTimeout(() => {
      store.notification = "";
    }, 3000);
  },

  RESET_PAGE(store) {
    store.isPageLoading = false;
    store.grayBackground = false;
    store.isMobileSearchOpen = false;
    store.searchText = "";
    store.isOnlyMobilePage = false;
    store.isOnlyTopGap = false;
    store.noNavigationBar = false;
  },

  SET_PAGE(store, payload) {
    store.isPageLoading = payload.isPageLoading;
    store.grayBackground = payload.grayBackground;
    store.isOnlyMobilePage = payload.isOnlyMobilePage;
    store.noNavigationBar = payload.noNavigationBar;
    store.isOnlyTopGap = payload.isOnlyTopGap;
  },

  TOGGLE_MOBILE_SEARCH(store, payload) {
    store.isMobileSearchOpen = payload ?? !store.isMobileSearchOpen;
  },

  TOGGLE_SHARE_MODAL(store, payload) {
    if (!store.isShareModalOpen) {
      if (payload?.url) {
        store.shareModalUrl = payload.url;
      }
      store.isShareModalOpen = true;
    } else {
      store.isShareModalOpen = false;
      store.shareModalUrl = null;
    }
  },

  SET_PAGE_LOADED(store) {
    store.isPageLoading = false;
  },

  SET_TRIAL_TARIFF_NOTICE(store, payload) {
    store.isTrialPeriodTariffOpen = payload;
  },

  OPEN_UNAUTH_MODAL(store) {
    store.isOpenUnauthModal = true;
  },

  CLOSE_UNAUTH_MODAL(store) {
    store.isOpenUnauthModal = false;
  },
};

const actions = {
  loadRegions({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/regions`)
        .then((response) => {
          commit("SET_REGIONS_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
