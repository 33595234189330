<script>
export default {
  name: "AppTabs",
  emits: ["update:modelValue"],

  props: {
    labelSelector: {
      type: String,
      default: "Ещё",
    },

    tabs: {
      type: Array,
      required: true,
    },

    modelValue: {
      type: String,
      required: true,
    },

    isParamStr: {
      type: Boolean,
      default: false,
    },

    nameRoute: {
      type: String,
      default: "",
    },
  },

  methods: {
    select(tabId) {
      if (this.isParamStr) {
        this.$router.push({
          query: { ...this.$route.query, tab: tabId },
        });
      }

      this.$emit("update:modelValue", tabId);
    },
  },
};
</script>

<template>
  <div class="app-tabs">
    <button
      v-for="tab in tabs"
      :key="tab.id"
      type="button"
      class="app-tabs__tab tab"
      :class="{
        tab_selected: tab.id === modelValue,
      }"
      @click="select(tab.id)"
    >
      <span>{{ tab.title }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.app-tabs {
  user-select: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: transparent;
  height: 48px;
  gap: 24px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    color: $text-fourth;
    white-space: nowrap;
    padding: 12px 0;
    transition: color 0.15s ease-out;
    height: 48px;
    border-bottom: 1px solid $stroke-fourth;

    &_selected {
      border-bottom: 2px solid $stroke-accent !important;
      color: $text-primary !important;
      padding-bottom: 10px !important;
      transition: border-bottom 50ms ease-out;
    }
  }
}
</style>
