<script setup>
import { computed, onMounted, ref, watch } from "vue";

defineOptions({
  name: "UiBottomSheet",
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },

  footer: {
    type: Boolean,
    default: false,
  },

  closeButton: {
    type: Boolean,
    default: false,
  },

  hideOptions: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(["update:modelValue", "hideOptions"]);

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      currentHeightType.value = "middle";
      const body = document.querySelector("body");
      body.style.overflow = "hidden";
    } else {
      const body = document.querySelector("body");
      body.style.removeProperty("overflow");
    }
  }
);

const currentHeightType = ref("middle");

// const VIEWPORT_HEIGHT =
//   window.innerHeight || document.documentElement.clientHeight;

const bottomSheetStyle = computed(() => {
  if (touchStartY.value !== null && delta.value !== null) {
    return {
      height: `${420 - delta.value}px`,
    };
  } else {
    return {
      height: "420px",
    };
  }
});

const touchStartY = ref(null);
const deltaDist = ref(0);

const touchstartHandler = (event) => {
  touchStartY.value = event.touches[0].clientY;
};

const delta = ref(null);

const touchmoveHandler = (event) => {
  if (touchStartY.value !== null) {
    delta.value = event.touches[0].clientY - touchStartY.value;
  }
};

watch(
  () => delta.value,
  (newValue) => {
    if (delta.value !== null) {
      if (newValue < 0) {
        delta.value = null;
      }
      if (newValue < 50) {
        delta.value = null;
      }
      deltaDist.value = newValue;
    }
  }
);

const removeDelta = () => {
  delta.value = null;
  touchStartY.value = null;
};

const dist = computed(() => {
  return `${420 - deltaDist.value}px`;
});

const touchendHandler = () => {
  if (delta.value > 50 && currentHeightType.value === "middle") {
    emit("hideOptions", true);
    setTimeout(() => {
      removeDelta();
      emit("update:modelValue", false);
      deltaDist.value = 0;
    }, 300);
  }
};

const closeSheet = () => {
  emit("hideOptions", true);
  setTimeout(() => {
    removeDelta();
    emit("update:modelValue", false);
  }, 300);
};

onMounted(() => {
  emit("update:modelValue", true);
});
</script>

<template>
  <Teleport to="body">
    <Transition mode="out-in" name="bottom-sheet">
      <div v-if="modelValue" class="ui-bottom-sheet" @click="closeSheet">
        <div
          v-if="modelValue"
          class="ui-bottom-sheet__container container"
          :class="[
            { container_open: !hideOptions },
            { container_close: hideOptions },
          ]"
          :style="bottomSheetStyle"
          @click.stop
        >
          <div
            class="header"
            @touchmove="touchmoveHandler"
            @touchstart="touchstartHandler"
            @touchend="touchendHandler"
          >
            <button
              v-if="closeButton"
              class="close-btn"
              type="button"
              @click="emit('update:modelValue', false)"
            >
              <span class="icon-close" />
            </button>
          </div>

          <div class="main" @scroll.stop>
            <slot></slot>
          </div>

          <div v-if="footer" class="footer"></div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.ui-bottom-sheet {
  display: none;
}

@media (max-width: 1024px) {
  .ui-bottom-sheet {
    overflow-y: hidden;
    overscroll-behavior-y: none;
    touch-action: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: block;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(3px);
    transition: opacity 0.3s ease;

    .container {
      border-radius: 32px 32px 0 0;
      background: $text-light;
      position: fixed;
      left: 0;
      width: 100%;
      display: grid;
      grid-template-rows: 32px 1fr;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &_open {
        animation: openSheet 0.2s linear forwards;
        bottom: 0;

        @keyframes openSheet {
          from {
            bottom: -420px;
          }

          to {
            bottom: 0;
          }
        }
      }

      &_close {
        animation: closeSheet 0.2s linear forwards;
        bottom: 0;

        @keyframes closeSheet {
          from {
            top: calc(100dvh - v-bind(dist));
          }

          to {
            top: 100dvh;
          }
        }
      }

      &:before {
        content: "";
        width: 32px;
        height: 4px;
        background: $stroke-fourth;
        border-radius: 1px;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
      }

      .header {
        background: $text-light;
        border-radius: 20px 20px 0 0;
        position: relative;

        .close-btn {
          cursor: pointer;
          padding: 0;
          background: inherit;
          border: none;
          position: absolute;
          top: 12px;
          right: 12px;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;

          > span {
            width: 24px;
            height: 24px;
            background: green;
          }

          &:hover > span {
            //todo: Добавить актуальный цвет
            background: violet;
          }

          &:active > span {
            //todo: Добавить актуальный цвет
            background: gray;
          }
        }
      }

      .main {
        background: $text-light;
        overflow-y: auto;
      }

      .footer {
        background: red;
      }
    }
  }
}

.bottom-sheet-leave-to {
  animation: closeauth 20s linear forwards;

  @keyframes closeauth {
    from {
      bottom: 100%;
    }

    to {
      bottom: 0;
    }
  }
}

.bottom-sheet-enter-from {
  animation: openauth 20s linear forwards;

  @keyframes openauth {
    from {
      bottom: 0;
    }

    to {
      bottom: 80%;
    }
  }
}

.bottom-sheet-enter-from .container,
.bottom-sheet-leave-to .container {
  bottom: -100%;
}
</style>
