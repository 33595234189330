<script setup>
import CustomTariffGrid from "@/components/CustomTariff/CustomTariffGrid.vue";
import CustomTariffTariffsSelector from "@/components/CustomTariff/CustomTariffTariffsSelector.vue";
import CustomTariffPeriodSelector from "@/components/CustomTariff/CustomTariffPeriodSelector.vue";
import CustomTariffFloatingPanel from "@/components/CustomTariff/CustomTariffFloatingPanel.vue";
import { onBeforeUnmount } from "vue";
import { useStore } from "vuex";

const store = useStore();

onBeforeUnmount(() => {
  store.commit("customTariff/RESET_TRIGGERS");
});
</script>

<template>
  <CustomTariffGrid>
    <CustomTariffTariffsSelector />
    <CustomTariffPeriodSelector />
  </CustomTariffGrid>
  <CustomTariffFloatingPanel />
</template>

<style scoped lang="scss"></style>
