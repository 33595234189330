import HttpClient from "@/api";

const state = {
  reactions: [],
};

const mutations = {
  SET_BLOG_REACTIONS(state, payload) {
    state.reactions = payload.reactions;
  },
};

const actions = {
  async sendReaction(store, payload) {
    const normalize = {
      name: null,
    };

    if (payload.name) {
      normalize.name = payload.name;
    }

    try {
      const response = await HttpClient.post(
        `api/blog/posts/${payload.post_id}/react`,
        normalize
      );

      store.commit("SET_BLOG_REACTIONS", {
        reactions: response.data.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
