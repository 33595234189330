<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "CitiesModal",

  computed: {
    ...mapState({
      regions: (state) => state.system.regions,
      window_width: (state) => state.system.window_width,
    }),
  },

  methods: {
    ...mapMutations({
      setUserRegion: "user/SET_USER_REGION",
      changeRegionsModalView: "system/CHANGE_REGIONS_MODAL_VIEW",
    }),

    selectAllRegions() {
      this.setUserRegion("");
      this.changeRegionsModalView();
    },

    selectRegions(region) {
      this.setUserRegion(region.id);
      this.changeRegionsModalView();
    },
  },
};
</script>

<template>
  <div class="cities-modal" @click="changeRegionsModalView(false)">
    <div class="cities-modal__wrapper" @click.stop>
      <button
        v-if="window_width > 1023"
        type="button"
        class="close-button"
        @click="changeRegionsModalView(false)"
      >
        <i class="icon-close" />
      </button>

      <div v-else class="cancel-button">
        <button type="button" @click="changeRegionsModalView(false)">
          Отмена
        </button>
      </div>

      <h1>Выберите регион</h1>

      <div class="cities-modal__list">
        <button type="button" @click="selectAllRegions()">Все регионы</button>

        <button
          v-for="region in regions"
          :key="region.id"
          type="button"
          @click="selectRegions(region)"
        >
          {{ region.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cities-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $background-overlay;
  z-index: 31;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    background: $background-light;
    align-items: flex-start;
    justify-items: flex-start;
  }

  &__wrapper {
    background: $background-light;
    padding: ($base-space * 10);
    border-radius: 24px;
    position: relative;
    max-height: 95vh;
    overflow: auto;
    overscroll-behavior: contain;

    @media (max-width: 1023px) {
      border-radius: 0;
      width: 100%;
      max-height: 100vh;
      padding: 0 ($base-space * 8) ($base-space * 10);
    }

    @media (max-width: 767px) {
      padding: 0 ($base-space * 5) ($base-space * 10);
    }

    @media (max-width: 374px) {
      padding: 0 ($base-space * 4) ($base-space * 10);
    }

    > h1 {
      @include h-1();
      color: $text-primary;

      @media (max-width: 1023px) {
        margin: ($base-space * 10) 0 0;
      }
    }

    > .cancel-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: ($base-space * 3) 0;

      > button {
        color: $text-accent;
        @include s-text-bold();
      }
    }
  }

  &__list {
    margin: ($base-space * 10) 0 0;
    display: grid;
    grid-template-columns: repeat(3, 280px);
    grid-gap: 0 ($base-space * 6);
    justify-items: start;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      margin: ($base-space * 8) 0 0;
      overflow: auto;
    }

    > button {
      @include m-text();
      padding: ($base-space * 2) 0;
      text-align: left;
      color: $text-primary;
    }
  }
}
</style>
