<script setup>
import { useStore } from "vuex";
import PageLoader from "@/components/Layouts/MainLayout/PageLoader.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const store = useStore();

const route = useRoute();

// const mobileWidth = computed(() => store.getters["system/isMobile"]);
const isCalendar = computed(() => route.name === "CalendarView");
const isNotification = computed(() => route.name === "ProfileNotifications");
const isMobileProfile = computed(() => route.name === "ProfileView");

const isPost = computed(() => {
  return route.name === "BlogPostPage" && !localStorage.token;
});

const isPostToken = computed(() => {
  return route.name === "BlogPostPage" && Boolean(localStorage.token);
});

const isBlogToken = computed(() => {
  return (
    (route.name === "BlogView" || route.name === "BlogPage") &&
    Boolean(localStorage.token)
  );
});

const isBlog = computed(() => {
  return (
    (route.name === "BlogView" || route.name === "BlogPage") &&
    !localStorage.token
  );
});
const isChannelEdit = computed(() =>
  ["CreateChannelView", "CreatePrivateChannelView", "EditChannelView"].includes(
    route.name
  )
);
const isMessenger = computed(() =>
  ["ChatChannelsView", "ChatCurrentChannelView"].includes(route.name)
);
</script>

<template>
  <main
    id="mainViewPage"
    class="main-view"
    :class="{
      'floating-action': store.state.system.isMobileFloatingActionVisible,
      'background-gray': store.state.system.grayBackground,
      'no-gaps': store.state.system.isOnlyMobilePage,
      'top-gaps': store.state.system.isOnlyTopGap,
      clean: store.state.system.noNavigationBar,
      'is-calendar': isCalendar,
      'is-notification': isNotification,
      'is-profile': isMobileProfile || (isChannelEdit && !isChannelEdit),
      'is-chat': isMessenger,
      'is-blog': isBlog,
      'is-blog-token': isBlogToken,
      'is-post': isPost,
      'is-post-token': isPostToken,
      'is-channel-edit': isChannelEdit,
    }"
  >
    <PageLoader v-if="store.state.system.isPageLoading" is-loading />
    <RouterView />
  </main>
</template>

<style scoped lang="scss">
.main-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: $base-space * 30;

  &.is-profile {
    margin-top: 0;
    padding: 0 0 66px;
  }

  &.is-blog {
    background: $background-ultralightlight-accent;
    padding-top: 40px;
    padding-bottom: 0;

    @media (max-width: 1023px) {
      padding-top: 0;
    }
  }

  &.is-notification {
    margin-top: 0;
    padding: 72px 0 0;
  }

  &.is-chat {
    margin-top: 0;
    padding: 0;
  }

  &.is-post {
    background: $background-ultralightlight-accent;
    padding: 0;
  }

  &.is-post-token {
    background: $background-ultralightlight-accent;
    padding-bottom: 0;

    @media (min-width: 1024px) {
      padding: 40px 0;
      height: 100%;
      overflow: auto;
    }

    @media (max-width: 1023px) {
      overflow: hidden;
      margin-top: 52px;
    }
  }

  &.is-blog-token {
    background: $background-ultralightlight-accent;
    padding-bottom: 0;

    @media (min-width: 1024px) {
      padding-top: 40px;
    }

    @media (max-width: 1023px) {
      margin-top: 60px;
      padding-bottom: 66px;
    }
  }

  &.is-channel-edit {
    margin-top: 0;
    padding: 96px 0 0 0;

    @media (max-width: 1023px) {
      padding: 0;
    }
  }

  &.is-calendar {
    @media (max-width: 968px) {
      padding-bottom: $base-space * 10;
    }

    @media (max-width: 767px) {
      padding-bottom: $base-space * 100;
    }

    @media (max-width: 590px) {
      padding-bottom: $base-space * 100;
    }

    @media (max-width: 430px) {
      padding-bottom: $base-space * 120;
    }

    @media (width: 540px) and (height: 720px) {
      padding-bottom: $base-space * 80;
    }

    @media (width: 280px) and (height: 653px) {
      padding-bottom: $base-space * 120;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 68px;
  }

  .floating-action {
    padding-bottom: $base-space * 30 + 72px;
  }

  .no-gaps {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @include _md {
      margin-top: 0 !important;
    }
  }

  .top-gaps {
    padding-bottom: 0 !important;
  }

  .clean {
    padding: 0 !important;
    margin: 0 !important;
    flex: 1;
  }

  @include _md {
    margin-top: 90px;
    padding-top: $base-space * 18;
    //padding-bottom: $base-space * 30;
  }

  @include _lg {
    padding-top: $base-space * 24;
  }
}
</style>
