import axios from "axios";

export const baseURL = import.meta.env.VITE_APP_BACKEND_URL;

fetch(`/`).then((data) => {
  if (data.headers.has("X-BASE-URL")) {
    API.defaults.baseURL = data.headers.get("X-BASE-URL");
    window.baseURL = data.headers.get("X-BASE-URL");
  }
});

const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && localStorage.token) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default API;
