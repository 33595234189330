import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vClickOutside from "click-outside-vue3";
import "@/scss/app.scss";
import VueClipboard from "vue3-clipboard";
import moment from "moment";
import "moment/dist/locale/ru";
import "moment/locale/ru";
import { createPinia } from "pinia";
const pinia = createPinia();

moment.locale("ru");

import * as Sentry from "@sentry/vue";

import * as amplitude from "@amplitude/analytics-browser";

const isProduction = import.meta.env.MODE === "production";

const app = createApp(App);

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

if (isProduction) {
  Sentry.init({
    app,
    dsn: "https://ce044c528a614d1cbed063a38013c780@sentry.iilab.ru/35",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend: (event) => {
      if (window.location.hostname !== "localhost") {
        return event;
      }
    },
  });

  amplitude.init("4d58c30026771a62412dbc72c3e5fc2c");

  amplitude.track("Sign Up");
}

app.use(store).use(router).use(vClickOutside).use(pinia).mount("#app");
