export const NOTIFICATIONS_TAB = [
  {
    title: "Все уведомления",
    id: "AllNotifications",
  },
  {
    title: "Непрочитанные",
    id: "UnreadNotifications",
  },
];
