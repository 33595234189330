<template>
  <header id="mobile-header"></header>
</template>

<style lang="scss" scoped>
#mobile-header {
  position: fixed;
  top: 0;
  z-index: 10;
  background: #fff;
  width: 100%;
}
</style>
