<script>
export default {
  name: "TextButton",

  props: {
    color: {
      type: String,
      default: "primary",
    },

    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: undefined,
      required: false,
    },

    type: {
      type: String,
      default: "button",
    },

    size: {
      type: String,
      default: "large",
    },

    iconPosition: {
      type: String,
      default: "left",
    },
  },
};
</script>

<template>
  <button
    class="ui-text-button"
    :class="[
      `ui-text-button_color_${color}`,
      `ui-text-button_size_${size}`,
      `ui-text-button_icon-position_${iconPosition}`,
    ]"
    :type="type"
  >
    <span v-if="icon" class="ui-text-button__icon" :class="`icon-${icon}`" />

    <span class="ui-text-button__label label">{{ label }}</span>
  </button>
</template>

<style scoped lang="scss">
.ui-text-button {
  width: fit-content;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  background: none;
  gap: 4px;

  &_icon-position {
    &_left {
      flex-direction: row;
    }

    &_right {
      flex-direction: row-reverse;
    }
  }

  &_color {
    gap: 8px;

    &_primary {
      color: $text-accent;

      .ui-text-button__icon {
        background: $text-accent;
      }

      &:hover {
        color: $text-accent;
      }
    }

    &_secondary {
      color: $text-primary;

      .ui-text-button__icon {
        background: $text-primary;
      }

      &:hover {
        color: $text-primary;
        text-decoration-color: $text-primary;

        .ui-text-button__icon {
          background: $text-primary;
        }
      }
    }

    &_negative {
      color: #f2101e;

      .ui-text-button__icon {
        background: #f2101e;
      }

      &:hover {
        color: #f2101e;
        text-decoration-color: #f2101e;

        .ui-text-button__icon {
          background: #f2101e;
        }
      }
    }
  }

  &_size {
    &_large {
      @include m-text;

      .ui-text-button__icon {
        width: 24px;
        height: 24px;
      }
    }

    &_medium {
      @include s-text;

      .ui-text-button__icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
