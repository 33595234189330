<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  tariff: {
    type: Object,
    required: true,
  },
});

const tariffIcon = computed(() => {
  switch (props.tariff.code) {
    case "resident":
      return {
        icon: "premium",
        color: "#005A9B",
      };
    case "master-candidate":
      return {
        icon: "business",
        color: "#560096",
      };
    case "master":
      return {
        icon: "vip-gold",
        color: "#82001A",
      };
    default: {
      return {
        icon: "",
        color: "#000",
      };
    }
  }
});
</script>

<template>
  <div class="icon" :style="{ backgroundColor: tariffIcon.color }">
    <i :class="`icon-${tariffIcon.icon}`"></i>
  </div>
</template>

<style scoped lang="scss">
.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  i {
    width: 24px;
    height: 24px;
    background: #fff;
  }
}
</style>
