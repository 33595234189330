<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import wordDeclension from "../../package/helpers/wordDeclension";

const store = useStore();

const tariffs = computed(() =>
  store.state.tariffs.tariffs
    .filter(
      (t) =>
        t.price_original > 0 && store.state.customTariff.selectedTariffs[t.id]
    )
    .map((t) => ({
      name: t.name,
      count: store.state.customTariff.selectedTariffs[t.id],
    }))
);

const priceFormatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});

const price = computed(() =>
  priceFormatter.format(store.state.customTariff.calculatedPrice)
);
</script>

<template>
  <ContentBox title="Параметры тарифа">
    <div class="lines">
      <div v-for="tariff in tariffs" :key="tariff.name" class="line">
        <div class="title">{{ tariff.name }}</div>
        <div class="middle" />
        <div class="value">{{ tariff.count }} шт.</div>
      </div>
      <div class="line">
        <div class="title">Период действия</div>
        <div class="middle" />
        <div class="value">
          {{ store.state.customTariff.months }}
          {{
            wordDeclension(store.state.customTariff.months, [
              "месяц",
              "месяца",
              "месяцев",
            ])
          }}
        </div>
      </div>
      <div class="total">
        <div class="title">Стоимость</div>
        <div class="value">{{ price }} / мес.</div>
      </div>
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.lines {
  display: flex;
  flex-direction: column;
  gap: $base-space * 4;

  .line {
    @include m-text;
    display: flex;
    gap: $base-space * 4;
    align-items: flex-end;

    .middle {
      flex: 1;
      border-bottom: 1px solid #e3e5ee;
    }
  }

  .total {
    @include m-text;
    margin-top: $base-space * 4;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      background: #ebedfa;
      border-radius: 8px;
      @include h-4;
      padding: $base-space * 2 $base-space * 3;
    }
  }
}
</style>
