import API from "@/api";

const state = {
  region: localStorage.region || "",
  user: "",
  promo_codes: "",
  cards: [],
};

const getters = {
  getSelectedRegion(store, _, rootState) {
    if (rootState.system.regions) {
      try {
        return rootState.system.regions.find(
          (region) => region.id === parseInt(store.region)
        );
      } catch {
        localStorage.removeItem("region");
        return "";
      }
    }
  },
};

const mutations = {
  SET_USER_REGION(store, payload) {
    localStorage.setItem("region", payload);
    store.region = payload;
  },

  SET_USER_INFORMATION(store, payload) {
    store.user = payload;
  },

  SET_USER_PROMO_CODES(store, payload) {
    store.promo_codes = payload;
  },

  ADD_USER_PROMO_CODE(store, payload) {
    store.promo_codes.push(payload);
  },
};

const actions = {
  createOtpCode(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/otp`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loginUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/login`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("tokenWs", response.data.broadcast_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  registrationUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/register`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("tokenWs", response.data.broadcast_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadInformationByInn(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/suggestion`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserInformation({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/me`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserStatistic() {
    return new Promise((resolve, reject) => {
      API.get(`api/me/invitedUsers`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUserInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/me`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadUserAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/me/media`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteUserAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      API.delete(`api/me/media`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserPromoCodes({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/me/invitecodes`)
        .then((response) => {
          commit("SET_USER_PROMO_CODES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createUserPromoCode({ commit }) {
    return new Promise((resolve, reject) => {
      API.post(`api/me/invitecodes`)
        .then((response) => {
          commit("ADD_USER_PROMO_CODE", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendSupportReport(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/report`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendCooperationReport(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/report/coop`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setUserNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/me/notifications`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePaymentCard(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/me/payments/authorizations/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserCardsList() {
    return new Promise((resolve, reject) => {
      API.get(`api/me/payments/authorizations`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancelTariffSubscription({ commit }) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/cancel`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadNotifications(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/me/notifications/all`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setPromocode(_, promocode) {
    return new Promise((resolve, reject) => {
      const params = { promocode };
      API.post(`api/me/distributorPromocode`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
