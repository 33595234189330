import API from "@/api";

const state = {
  statisticRepresentation: [],
  userList: [],
  page: 1,
  lastPage: 1,
  totalPage: 0,
  current_page: 1,
  total: 0,
};

const mutations = {
  SET_STATISTICS_LIST(store, data) {
    store.statisticRepresentation = data;
  },

  SET_USER_STATISTICS_LIST(store, data) {
    if (data.infinityScrolled) {
      store.userList = [...store.userList, ...data.userList];
      store.lastPage = data.meta.last_page;
      store.page = data.meta.current_page;
    } else {
      store.userList = data.userList;
      store.page = 1;
    }
    store.total = data.meta.total;
  },

  SET_PAGE(store) {
    store.page += 1;
  },

  SET_LAST_PAGE(store, data) {
    store.totalPage = data;
  },
};

const actions = {
  getListUsersCurrentRepresentation(store, data) {
    const params = {
      page: store.state.page,
    };

    Object.entries(data).forEach((item) => {
      if (item[1]) {
        if (Array.isArray(item[1]) && item[1].length === 0) {
          return;
        } else if (item[0] === "infinityScrolled") {
          return;
        }
        params[item[0]] = item[1];
      }
    });

    if (data.infinityScrolled && params.page <= store.state.lastPage) {
      params.page += 1;
      store.commit("SET_PAGE");
    } else if (data.infinityScrolled) {
      return;
    } else if (params.page > store.state.lastPage) {
      params.page = 1;
      store.commit("SET_PAGE");
    }

    return new Promise((resolve, reject) => {
      API.get(`api/me/representation/users`, { params })
        .then((response) => {
          store.commit("SET_LAST_PAGE", response.data.meta.last_page);
          store.commit("SET_USER_STATISTICS_LIST", {
            userList: response.data.data,
            meta: response.data?.meta,
            infinityScrolled: data.infinityScrolled ?? false,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStatisticsCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/me/representation/statistics`)
        .then((response) => {
          commit("SET_STATISTICS_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // loadCourseById(_, data) {
  //   return new Promise((resolve, reject) => {
  //     API.get(`api/courses/external/${data.id}`)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
