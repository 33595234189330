import HttpClient from "@/api";

const state = {
  comments: [],
  commentTotal: 0,
  perPage: 10,
};

const mutations = {
  SET_COMMENT(store, payload) {
    store.comments = payload;
  },
  SET_TOTAL(store, payload) {
    store.commentTotal = payload;
  },

  SET_PER_PAGE(store, payload) {
    store.perPage = payload;
  },
};

const actions = {
  getComments(store, payload) {
    const params = {
      per_page: store.state.perPage,
    };

    if (payload.infinityScroll && params.per_page < store.state.commentTotal) {
      params.per_page += 10;

      store.commit("SET_PER_PAGE", params.per_page);
    } else if (payload.infinityScroll) {
      return;
    }

    return new Promise((resolve, reject) => {
      HttpClient.get(
        `api/communities/${payload.community_id}/posts/${payload.post_id}/comments`,
        { params }
      )
        .then((response) => {
          store.commit("SET_COMMENT", response.data.data);
          store.commit("SET_TOTAL", response.data.meta.total);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendComment(store, payload) {
    const normalize = {
      text: payload.text,
      reply_id: payload.reply_id,
    };

    return new Promise((resolve, reject) => {
      HttpClient.post(
        `api/communities/${payload.community_id}/posts/${payload.post_id}/comments`,
        normalize
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeComment(store, payload) {
    const normalize = {
      text: payload.text,
      reply_id: payload.reply_id,
    };

    return new Promise((resolve, reject) => {
      HttpClient.patch(
        `api/communities/${payload.community_id}/posts/${payload.post_id}/comments/${payload.id}`,
        normalize
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteComment(_, payload) {
    return new Promise((resolve, reject) => {
      HttpClient.delete(
        `api/communities/${payload.community_id}/posts/${payload.post_id}/comments/${payload.id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
