import HttpClient from "@/api";

const state = {
  comments: null,
  hashTableComments: null,
  allLinks: null,
  metaPage: null,
  commentTotal: 0,
  perPage: 5,
  currentLink: null,
  currentMessage: null,
  isScrollToBottom: false,
  currentNameLink: "",
};

const mutations = {
  SET_BLOG_COMMENTS(state, payload) {
    state.comments = payload.comments;
    state.allLinks = payload.links;
    state.metaPage = payload.metaPage;
    state.commentTotal = payload.total;
  },

  SET_HASH_TABLE_COMMENTS(state, payload) {
    state.hashTableComments = payload.hashTableComments;
  },

  RESET_BLOG_COMMENTS(state) {
    state.comments = null;
    state.hashTableComments = null;
    state.allLinks = null;
    state.metaPage = null;
    state.commentTotal = 0;
    state.currentLink = null;
    state.currentNameLink = "";
  },

  UPDATE_BLOG_COMMENTS(state, payload) {
    if (state.currentNameLink === "prev") {
      state.comments = [...payload.comments, ...state.comments];
    } else {
      state.comments = [...state.comments, ...payload.comments];
    }
    state.allLinks = payload.links;
    state.commentTotal = payload.total;
  },

  CLEAR_COMMENTS(store) {
    store.comments = [];
    store.allLinks = null;
  },

  SET_TOTAL(state, payload) {
    state.commentTotal = payload;
  },

  SET_PER_PAGE(state, payload) {
    state.perPage = payload;
  },

  TOGGLE_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_CURRENT_MESSAGE(state, payload) {
    state.currentMessage = payload.message;
  },

  ADD_MESSAGE(state) {
    state.comments.push(state.currentMessage);
    state.isScrollToBottom = true;
  },

  DISABLE_SCROLL(state) {
    state.isScrollToBottom = false;
  },

  CLEAR_CURRENT_MESSAGE(state) {
    state.currentMessage = null;
  },

  DELETE_BLOG_COMMENT(state, comment_id) {
    state.comments = state.comments.filter(
      (comment) => comment.id !== comment_id
    );
  },

  EDIT_BLOG_COMMENT(state, payload) {
    const comment_id = payload.comment_id;
    const text = payload.text;
    state.comments = state.comments.map((comment) =>
      comment.id === comment_id ? { ...comment, text: text } : comment
    );
  },
};

const actions = {
  async getComments(store, payload) {
    const params = {
      per_page: store.state.perPage,
      page: payload.page,
    };
    try {
      const response = await HttpClient.get(
        `api/blog/posts/${payload.post_id}/comments`,
        { params }
      );
      store.commit("SET_BLOG_COMMENTS", {
        comments: response.data.data,
        links: response.data.links,
        metaPage: response.data.meta,
        total: response.data.meta.total,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getInfoPageComment(store, payload) {
    try {
      const response = await HttpClient.get(
        `api/blog/posts/${payload.post_id}/comments/pages`
      );
      store.commit("SET_HASH_TABLE_COMMENTS", {
        hashTableComments: response.data.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getCommentsFromLink(store, payload) {
    try {
      store.state.currentLink = null;
      store.state.currentNameLink = "";
      store.state.currentNameLink = payload.link;

      switch (payload.link) {
        case "prev":
          store.state.currentLink = store.state.allLinks?.prev;
          break;

        case "next":
          store.state.currentLink = store.state.allLinks?.next;
          break;

        case "first":
          store.state.currentLink = store.state.allLinks?.first;
          break;

        case "last":
          store.state.currentLink = store.state.allLinks?.last;
          store.commit("CLEAR_COMMENTS");

          break;
      }

      if (!store.state.currentLink) {
        return;
      }

      const response = await HttpClient.get(store.state.currentLink);

      store.commit("UPDATE_BLOG_COMMENTS", {
        comments: response.data.data,
        links: response.data.links,
        metaPage: response.data.meta,
        total: response.data.meta.total,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async sendComment(store, payload) {
    const normalize = {
      text: payload.message,
    };

    if (payload.reply_id) {
      normalize.response_to_id = payload.reply_id;
    }

    try {
      const response = await HttpClient.post(
        `api/blog/posts/${payload.post_id}/comments`,
        normalize
      );

      store.commit("SET_CURRENT_MESSAGE", {
        message: response.data.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async editComment(store, payload) {
    const normalize = {
      text: payload.message,
    };

    try {
      const response = await HttpClient.post(
        `api/blog/posts/${payload.post_id}/comments/${payload.comment_id}`,
        normalize
      );
      store.commit("EDIT_BLOG_COMMENT", {
        comment_id: payload.comment_id,
        text: response.data.data.text,
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteComment(store, payload) {
    try {
      const response = await HttpClient.delete(
        `api/blog/posts/${payload.post_id}/comments/${payload.comment_id}`
      );

      store.commit("DELETE_BLOG_COMMENT", payload.comment_id);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
