import sanitizeHtml from "sanitize-html";

export default function removeTags(text) {
  return sanitizeHtml(text, {
    allowedTags: ["a"],
    allowedAttributes: {
      a: ["href", "target", "title"],
    },
  });
}
