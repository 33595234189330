<script setup>
import { computed, defineProps, ref, watch } from "vue";
import ContentBox from "@/components/Ui/ContentBox.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import { useStore } from "vuex";
import CustomTariffIcon from "@/components/CustomTariff/CustomTariffIcon.vue";
import wordDeclension from "../../package/helpers/wordDeclension";
import { useDebounceFn } from "@vueuse/core";
import { useFieldArray, useForm, useFormErrors } from "vee-validate";
import Yup from "@/lib/yup";

const schema = Yup.object().shape({
  users: Yup.array().of(
    Yup.string()
      .required("Заполните поле")
      .min(12, "Неверный номер телефона")
      .phoneAvailableForCorporateTariff()
  ),
});

const store = useStore();

const isOpen = ref(true);

const showErrors = computed(() => store.state.customTariff.triggers > 0);

const props = defineProps({
  tariffId: {
    type: Number,
    required: true,
  },
});

const form = useForm({
  initialValues: {
    users: [...store.state.customTariff.users[props.tariffId]],
  },
  validationSchema: schema,
});

const errors = useFormErrors();

const { fields } = useFieldArray("users");

const tariff = computed(() =>
  store.state.tariffs.tariffs.find((t) => t.id === props.tariffId)
);

const handleToggle = () => {
  isOpen.value = !isOpen.value;
};

const saveUsers = useDebounceFn((values, errors) => {
  store.commit("customTariff/UPDATE_USERS", {
    id: props.tariffId,
    users: values.users,
  });
  store.commit(
    "customTariff/SET_ACCOUNTS_ERROR",
    !!Object.values(errors).length
  );
}, 500);

watch([form.values, form.errors], (v) => {
  saveUsers(v[0], v[1]);
});
</script>

<template>
  <ContentBox>
    <div class="header" :class="{ opened: isOpen }" @click="handleToggle">
      <CustomTariffIcon :tariff="tariff" />
      <div class="info">
        <div class="title">{{ tariff.name }}</div>
        <div class="count">
          {{ fields.length }}
          {{
            wordDeclension(fields.length, ["аккаунт", "аккаунта", "аккаунтов"])
          }}
        </div>
      </div>
      <div class="arrow">
        <i class="icon-arrow-down"></i>
      </div>
    </div>
    <div v-if="isOpen" class="grid">
      <div v-for="(field, idx) in fields" :key="idx" class="grid-el">
        <div class="icon">
          <i class="icon-person" />
          <span class="count">{{ idx + 1 }}</span>
        </div>
        <AppInput
          :id="`user-${idx}`"
          v-model="field.value"
          input-mode="text"
          required
          mask="+7##########"
          :error="errors[`users[${idx}]`]"
          :hide-error="!showErrors"
        >
          <template #left>
            <div class="input-icon">
              <i class="icon-phone"></i>
            </div>
          </template>
        </AppInput>
      </div>
    </div>
  </ContentBox>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  gap: $base-space * 4;

  &.opened {
    padding-bottom: $base-space * 5;

    .arrow {
      transform: rotate(0);
    }
  }

  &:hover {
    cursor: pointer;
  }

  .title {
    @include m-text-bold;
  }
  .description {
    @include xs-text;
    color: $text-third;
  }
  .arrow {
    margin-left: auto;
    transform: rotate(180deg);

    i {
      width: 24px;
      height: 24px;
      background-color: #000;
    }
  }
}
.grid {
  display: flex;
  flex-direction: column;
  gap: $base-space * 4;

  .grid-el {
    display: flex;
    align-items: center;
    gap: $base-space * 3;

    .icon {
      min-width: 32px;
      height: 32px;
      border-radius: 20em;
      background: #ebedfa;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      i {
        width: 24px;
        height: 24px;
        background-color: $text-accent;
      }

      .count {
        position: absolute;
        background: #ebedfa;
        border-radius: 20em;
        right: 3px;
        bottom: 3px;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-accent;
        @include xs-text;
      }
    }
  }
}
</style>
