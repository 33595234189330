import API from "@/api";

const state = {
  instructions: [],
};

const getters = {
  instructionsImages(state) {
    return state.instructions
      .filter((inst) => inst.type === "image")
      .sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
  },

  instructionsDocs(state) {
    return state.instructions
      .filter((inst) => inst.type === "document")
      .sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
  },

  instructionsFaqs(state) {
    return state.instructions
      .filter((inst) => inst.type === "faq")
      .sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
  },
};

const mutations = {
  SET_INSTRUCTION_LIST(store, payload) {
    store.instructions = payload;
  },
};

const actions = {
  getInstructions({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/instructions`)
        .then((response) => {
          commit("SET_INSTRUCTION_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
