import API from "@/api";

const actions = {
  loadNewsList(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/news`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadArticleById(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/news/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
