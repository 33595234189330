<script setup>
import { useStore } from "vuex";

const store = useStore();
</script>

<template>
  <div
    id="floating-mobile-wrapper"
    :class="{
      'floating-mobile-wrapper':
        store.state.system.isMobileFloatingActionVisible,
    }"
  ></div>
</template>

<style scoped lang="scss">
.floating-mobile-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(58px + env(safe-area-inset-bottom));
  transition: bottom 0.1s linear;
  padding: $base-space * 2 $base-space * 5;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);

  @include _md {
    display: none;
  }
}
</style>
