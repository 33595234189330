<script setup>
import ContentBox from "@/components/Ui/ContentBox.vue";
import { useStore } from "vuex";
import CustomTariffAccordeon from "@/components/CustomTariff/CustomTariffAccordeon.vue";
import { computed } from "vue";

const store = useStore();

const tariffs = computed(() =>
  Object.entries(store.state.customTariff.users)
    .filter((u) => !!u[1].length)
    .map((u) => u[0])
);
</script>

<template>
  <ContentBox
    title="Привязка аккаунтов"
    description="Укажите номера телефонов, к которым будут привязаны купленные тарифы"
  >
  </ContentBox>
  <CustomTariffAccordeon
    v-for="tariffId in tariffs"
    :key="tariffId"
    :tariff-id="Number(tariffId)"
  />
</template>

<style scoped lang="scss"></style>
