import API from "@/api";

const state = {
  tariffs: "",
};

const getters = {
  getCurrentTitleTariff: (state) => (id) => {
    const tariff = state.tariffs.find((item) => item.id === id);

    return tariff ? tariff.name : "";
  },
};

const mutations = {
  SET_TARIFFS_LIST(store, payload) {
    store.tariffs = payload;
  },
};

const actions = {
  loadTariffsList({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/tariffs`)
        .then((response) => {
          commit("SET_TARIFFS_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  purchaseTariff(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${data.id}/purchase`, {
        is_save_card: data.is_save_card,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  purchaseTrialTariff(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${data}/trial`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  purchaseTariffByCardTariff(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${data.id}/purchase/${data.card}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
