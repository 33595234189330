<script setup></script>

<template>
  <div class="success container mobile-paddings">
    <div class="content">
      <div class="icon">
        <i class="icon-stars"></i>
      </div>
      <div class="title">Тариф оформлен</div>
      <div class="description">
        Поздравляем! Корпоративный тариф оформлен. Подписки будут активированы
        на привязанных аккаунтах в ближайшее время.
      </div>
    </div>
    <RouterLink
      :to="{ name: 'ProfileView' }"
      class="button button_full-width action"
      >Вернуться к профилю</RouterLink
    >
  </div>
</template>

<style scoped lang="scss">
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  .action {
    margin-top: auto;
  }
  .content {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20vh;
    .icon {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20em;
      background: #ebedfa;

      i {
        background: $text-accent;
        width: 37px;
        height: 37px;
      }
    }
    .title {
      margin-top: $base-space * 6;
      @include h-1;
    }
    .description {
      margin-top: $base-space * 2;
      @include m-text;
      text-align: center;
    }
  }
}
</style>
