<script setup>
import AppModal from "@/components/Ui/AppModal.vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

const isOpen = computed(() => store.state.system.isShareModalOpen);

const shareLinks = [
  { id: "copy", title: "Коп. ссылку" },
  { id: "vk", title: "VK" },
  { id: "telegram", title: "Telegram" },
  { id: "ok", title: "ОК" },
];

const handleToggle = () => {
  store.commit("system/TOGGLE_SHARE_MODAL");
};

const handleShare = (option) => {
  switch (option.id) {
    case "vk": {
      window.open(
        `https://vk.com/share.php?url=${encodeURIComponent(
          store.state.system.shareModalUrl ?? window.location.href
        ).replaceAll(" ", "")}`
      );
      handleToggle();
      break;
    }

    case "telegram": {
      window.open(
        `https://t.me/share/url?url=${encodeURIComponent(
          store.state.system.shareModalUrl ?? window.location.href
        ).replaceAll(" ", "")}`
      );
      handleToggle();
      break;
    }

    case "ok": {
      window.open(
        `https://connect.ok.ru/offer
                  ?url=${encodeURIComponent(
                    store.state.system.shareModalUrl ?? window.location.href
                  ).replaceAll(" ", "")}`
      );
      handleToggle();
      break;
    }

    default: {
      navigator.clipboard.writeText(
        (store.state.system.shareModalUrl ?? window.location.href).replaceAll(
          " ",
          ""
        )
      );
      handleToggle();
    }
  }
};
</script>

<template>
  <AppDrawer
    v-if="isOpen && store.state.system.window_width < 1024"
    :title="store.state.system.shareModalText || 'Поделиться'"
    @close="handleToggle"
  >
    <div class="share">
      <button
        v-for="item in shareLinks"
        :key="item.id"
        type="button"
        :class="`share-${item.id}`"
        @click="handleShare(item)"
      >
        <span><i :class="`icon-${item.id}`" /></span>
        {{ item.title }}
      </button>
    </div>
  </AppDrawer>

  <AppModal
    v-if="isOpen && store.state.system.window_width > 1023"
    @close="handleToggle"
  >
    <div class="share-modal">
      <h3>{{ store.state.system.shareModalText || "Поделиться" }}</h3>

      <div class="share">
        <button
          v-for="item in shareLinks"
          :key="item.id"
          type="button"
          :class="`share-${item.id}`"
          @click="handleShare(item)"
        >
          <span><i :class="`icon-${item.id}`" /></span>
          {{ item.title }}
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style lang="scss" scoped>
.share {
  margin: ($base-space * 8) 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: $base-space;

  @media (max-width: 1023px) {
    margin: ($base-space * 6) 0 0;
  }

  > button {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: $base-space * 2;
    width: 72px;
    @include xxs-text-bold();
    color: $text-fifth;
    text-align: center;

    > span {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: 32px;
        height: 32px;
        background: $background-light;
      }
    }
  }

  &-copy {
    > span {
      background: $background-accent;
    }
  }

  &-vk {
    > span {
      background: #0077ff;
    }
  }

  &-telegram {
    > span {
      background: #24a2e0;
    }
  }

  &-ok {
    > span {
      background: #e9983e;
    }
  }
}

.share-button {
  width: 24px;
  height: 24px;

  > i {
    width: 24px;
    height: 24px;
    background: $text-primary;
  }
}

.share-modal {
  max-width: 440px;

  > h3 {
    @include h-2();
  }
}
</style>
