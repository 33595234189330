<script setup>
import CustomTariffGrid from "@/components/CustomTariff/CustomTariffGrid.vue";
import CustomTariffLinkAccounts from "@/components/CustomTariff/CustomTariffLinkAccounts.vue";
import CustomTariffFloatingPanel from "@/components/CustomTariff/CustomTariffFloatingPanel.vue";
import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();

const { push } = useRouter();

onBeforeMount(() => {
  const count = Object.values(store.state.customTariff.selectedTariffs).reduce(
    (acc, c) => acc + c,
    0
  );

  if (count < 3) {
    {
      store.commit("system/SET_NOTIFICATION", {
        type: "error",
        message: `Выберите минимум 3 тарифа`,
      });
      push({ name: "ProfileCustomTariffSelect" });
    }
  }
  if (store.state.customTariff.months < 1) {
    store.commit("system/SET_NOTIFICATION", {
      type: "error",
      message: `Выберите длительность подписки`,
    });
    push({ name: "ProfileCustomTariffSelect" });
  }
});
</script>

<template>
  <CustomTariffGrid>
    <CustomTariffLinkAccounts />
  </CustomTariffGrid>
  <CustomTariffFloatingPanel />
</template>

<style scoped lang="scss"></style>
