<script setup>
import CustomTariffSteps from "@/components/Layouts/CustomTariffLayout/CustomTariffSteps.vue";
import OverrideMobileHeader from "@/components/Layouts/MainLayout/OverrideMobileHeader.vue";
import { useRouter } from "vue-router";
import usePage from "@/hooks/usePage";

const { push } = useRouter();

usePage({
  grayBackground: true,
  isOnlyMobilePage: false,
});

const handleCancel = () => {
  push({ name: "ProfileTariffs" });
};
</script>

<template>
  <OverrideMobileHeader
    title="Настройка тарифа"
    :custom-action="{
      title: 'Отмена',
    }"
    @action="handleCancel"
  />

  <div class="container">
    <CustomTariffSteps />
    <RouterView />
  </div>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
