<script>
export default {
  name: "AppSelector",
  emits: ["select"],

  props: {
    selectedOption: {
      type: [Object, String],
      default: undefined,
    },

    label: {
      type: String,
      default: undefined,
    },

    options: {
      type: Array,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: [Boolean, String],
      default: undefined,
    },

    error: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },

    optionName: {
      type: String,
      default: "name",
    },

    optionValue: {
      type: String,
      default: "id",
    },
  },

  data() {
    return {
      show_options: false,
    };
  },

  methods: {
    closeSelectorOptions() {
      this.show_options = false;
    },

    selectOption(option) {
      this.$emit("select", option);
      this.show_options = false;
    },
  },
};
</script>

<template>
  <div v-click-outside="closeSelectorOptions" class="app-selector">
    <p>
      {{ label }} <span v-if="required" class="app-selector_required">*</span>
      <span v-if="notRequired" class="app-selector_not-required">
        - Необязательно
      </span>
    </p>

    <div class="app-selector__wrapper">
      <button
        :disabled="disabled"
        type="button"
        class="app-selector__button"
        :class="{
          'app-selector__button_active': show_options,
          'app-selector__button_error': error,
        }"
        @click="show_options = !show_options"
      >
        <span :class="{ 'app-selector__placeholder': !selectedOption }">
          {{ selectedOption ? selectedOption[optionName] : placeholder }}
        </span>

        <i class="icon-arrow-down" />
      </button>

      <div v-if="show_options" class="app-selector__options">
        <button
          v-for="option in options"
          :key="option[optionValue]"
          type="button"
          @click="selectOption(option)"
        >
          {{ option[optionName] }}
        </button>
      </div>
    </div>

    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<style scoped lang="scss">
.app-selector {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $base-space * 2;
  width: 100%;

  > p {
    color: $text-third;
    @include s-text();
    padding: 0 0 0 ($base-space);
  }

  &_required {
    @include s-text();
    color: $text-error;
  }

  &_not-required {
    @include s-text();
    color: $text-fifth;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include m-text();
    color: $text-primary;
    height: 56px;
    border-radius: 16px;
    border: 1px solid $stroke-third;
    padding: 0 ($base-space * 5);

    &:disabled {
      cursor: not-allowed;
      &:hover {
        border: 1px solid $stroke-third;
      }
    }

    > i {
      width: 24px;
      height: 24px;
      background: $text-fifth;
      transition: ease-in 0.05s;
    }

    &:hover {
      border-color: $stroke-second;
    }

    &_active {
      border-color: $stroke-accent;

      &:hover {
        border-color: $stroke-accent;
      }

      > i {
        transform: rotate(180deg);
      }
    }

    &_error {
      border-color: $stroke-error;
    }
  }

  &__placeholder {
    color: $text-fifth;
  }

  &__options {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    padding: ($base-space * 2) 0;
    @include main-shadow();
    border-radius: 12px;
    z-index: 3;
    background: $background-light;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 220px;
    overflow: auto;
    overscroll-behavior: contain;

    > button {
      padding: ($base-space * 2) ($base-space * 4);
      color: $text-primary;
      @include s-text();
    }
  }

  > span {
    padding: 0 0 0 ($base-space);
  }
}
</style>
